app.constant('entityMap', {
  anviz: {
    key: "anviz",
    title: "api.pages.title.anviz",
    list: { admin: false },
    edit: { admin: false },
    modal: 'md'
  },
  arho: {
    key: "arho",
    title: "api.pages.title.arho",
    list: { admin: false },
    edit: { admin: false },
    modal: 'md'
  },
  attendance: {
    key: "attendance",
    title: "api.pages.title.attendance",
    list: { admin: false }
  },
  connectivityIssue: {
    key: "connectivityIssue",
    title: "api.pages.title.connectivityIssue",
    list: { admin: false }
  }
});