app.constant('sidebar', [{
  title: "ARHO",
  icon: "fa fa-cubes",
  subMenu: [{
    title: "Instâncias",
    stateRef: "arho-list",
    fixedHref: "#/admin/arho/list"
  }, {
    title: "Dispositivos",
    stateRef: "anviz-list",
    fixedHref: "#/admin/anviz/list"
  }, {
    title: "Picagens",
    stateRef: "attendance-list",
    fixedHref: "#/admin/attendance/list"
  }, {
    title: "Networking",
    stateRef: "connectivityIssue-list",
    fixedHref: "#/admin/connectivityIssue/list"
  }]
}, {
  title: "Administração",
  icon: "fa fa-cogs",
  clearance: 1,
  subMenu: [{
    title: "Novo Acesso",
    stateRef: "user-new",
    fixedHref: "#/admin/user/edit",
  }, {
    title: "Gerir Acessos",
    stateRef: "user-list",
    fixedHref: "#/admin/user/list",
  }, {
    title: "Emails Enviados",
    stateRef: "message-list",
    fixedHref: "#/admin/message/list",
  }, {
    title: "Logs de Atividade",
    stateRef: "activity-list",
    fixedHref: "#/admin/activity/list",
  }, {
    title: "Ver licenças",
    stateRef: "license-list",
    fixedHref: "#/admin/license/list"
  }, {
    title: "Ver módulos",
    stateRef: "module-list",
    fixedHref: "#/admin/module/list"
  }]
}, {
  title: "Sistema",
  icon: "fa fa-bug",
  admin: true,
  subMenu: [{
    title: "Perfis de Utilizador",
    stateRef: "role-list",
    fixedHref: "#/admin/role/list",
  }, {
    title: "Templates de Email",
    stateRef: "alert-list",
    fixedHref: "#/admin/alert/list",
  }, {
    title: "Agendamento de Manutenção",
    stateRef: "maintenance-list",
    fixedHref: "#/admin/maintenance/list",
  }, {
    title: "Países",
    stateRef: "country-list",
    fixedHref: "#/admin/country/list",
  }, {
    title: "Feriados",
    stateRef: "holiday-list",
    fixedHref: "#/admin/holiday/list",
  }, {
    title: "Logs de Sistema",
    stateRef: "error-list",
    fixedHref: "#/admin/error/list",
  }, {
    title: "Tarefas",
    stateRef: "task-list",
    fixedHref: "#/admin/task/list",
  }]
}]);