app.controller('anvizCtrl', function ($scope, $entity, blockUI) {
  $scope.toggleActive = function (item) {
    if (!item._id) return;

    blockUI.start("A guardar...");
    $entity.update("anviz", item._id, { active: item.active }).then(function (response) {
    }).catch(function (response) {
      swal("Não foi possível guardar", response.data, "error");
    }).finally(function () {
      blockUI.stop();
    });
  };
});