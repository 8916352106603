app.controller('activationCtrl', function ($scope, $auth, $location, $stateParams, $page, zapi) {
  $page.setTitle('Ativação de Conta');

  $scope.user = null;

  $auth.activateAccount($stateParams.code, function (err, user) {
    if (err) return swal("Atenção", err, "warning");
    $scope.user = user;
  });

  $scope.changePassword = function (item) {
    $auth.changePassword($scope.user, item.password, item.repeat).then(function (response) {
      $location.path('/');
    }).catch(function (response) {
      swal("Não foi possível alterar a password", response.data || "O servidor exdeceu o tempo limite de resposta, por favor tente novamente mais tarde", "warning");
    });
  };

  initialize();

  function initialize() {
    if (!$stateParams.code) return swal("Código de ativação inválido", "Certifique-se que seguiu o endereço exactamente como enviado no email", "warning");
    var codes = $stateParams.code.split('&');
    if (codes.length !== 2) return swal("Código de ativação inválido", "Certifique-se que seguiu o endereço exactamente como enviado no email", "warning");

    $auth.activateAccount(codes).then(function (response) {
      $scope.user = response.data;
    }).catch(function (response) {
      swal("Atenção", response.data || "O servidor exdeceu o tempo limite de resposta, por favor tente novamente mais tarde", "warning");
    });
  }
});
