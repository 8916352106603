app.controller('arhoCtrl', function ($scope, $modal, $entity, blockUI) {
  $scope.newAnviz = function (arho) {
    var anviz = {
      arho: {
        _id: arho._id,
        name: arho.name
      }
    };
    $modal.open('arhoAnviz', 'md', anviz);
  };
  $scope.toggleActive = function (item) {
    if (!item._id) return;

    blockUI.start("A guardar...");
    $entity.update("arho", item._id, { active: item.active }).then(function (response) {
    }).catch(function (response) {
      swal("Não foi possível guardar", response.data, "error");
    }).finally(function () {
      blockUI.stop();
    });
  };
});