(function ($) {
  "use strict";
  new WOW().init();
})(jQuery);

var app = angular.module('app', [
  'ngSanitize',
  'ngAnimate',
  'ui.bootstrap',
  'blockUI',
  'angular-loading-bar',
  'chart.js',
  'btford.socket-io',
  'ngXlsx',
  'ngTable',
  'selector',
  'angularMoment',
  'checklist-model',
  'gettext',
  'ngMessages',
  'ui.router',
  'moment-picker',
  'zapi',
  'ngLetterAvatar',
  'ngCookies',

  'angularUtils.directives.dirPagination',
  'toastr',
]);

app.config(function ($stateProvider, $urlRouterProvider, IdleProvider, zapiProvider, entityMap, states) {
  zapiProvider.setMap(entityMap);
  zapiProvider.setIdle(false);
  // IdleProvider.idle(5 * 60); //5minutes idle to warn
  // IdleProvider.timeout(5); //5seconds after warn to logout

  $urlRouterProvider.otherwise('/');
  for (var key in states) $stateProvider.state(key, states[key]);
});

app.run(function ($rootScope, $location, $auth, gettextCatalog, translations) {
  gettextCatalog.setStrings('pt', translations.pt);
  gettextCatalog.debug = true;
  gettextCatalog.setCurrentLanguage('pt');

  $rootScope.$on('$locationChangeStart', $auth.getNextRoute(function (err, user, event, next, current) {
    $rootScope.login = (err ? {} : user) || {};
    $rootScope.$broadcast('setMenu', next);
    if (err) {
      swal("Acesso Proíbido", err, "warning");
      return $location.path("/");
    }
  }));
});

app.controller('indexCtrl', function ($page, $scope, $rootScope, $timeout, $interval, $cookies, $auth, $entity, baSidebarService, $modal, sidebar) {
  //globals
  $scope.Page = $page;
  $scope.$baSidebarService = baSidebarService;
  $scope.menuItems = [];
  $scope.openModal = $modal.open;
  $scope.login = function () {
    return $auth.currentUser || {};
  };
  $scope.logout = $auth.logout;
  $scope.isAdmin = $auth.isAdmin;
  $scope.isLoggedIn = $auth.isLoggedIn;
  $scope.isPageAnonymous = $auth.isPageAnonymous;
  $scope.isAnonymous = $auth.isAnonymous;
  $scope.hasClearance = $auth.hasClearance;
  $scope.isOwner = function () {
    if ($scope.isLoggedIn()) return $rootScope.login.owner === $rootScope.login.username;
    else return false;
  };

  //cookies
  $scope.acceptCookies = $cookies.get("acceptCookies") || false;
  $scope.closeCookies = function () {
    $cookies.put("acceptCookies", true);
    $scope.acceptCookies = true;
  };

  //msg center
  //$scope.shortcuts = shortcuts;
  $scope.messages = [];
  $scope.notifications = [];
  $timeout(updateMessageCenter, 2 * 1000);
  $interval(updateMessageCenter, 60 * 1000);
  function updateMessageCenter() {
  }

  //events
  $rootScope.$on('setMenu', function (event, next) {
    var exp;
    if (next) {
      var start = next.indexOf('#/') + 2;
      var end = next.indexOf('/', start);
      if (end === -1) end = next.indexOf('?', start);
      if (end === -1) end = next.indexOf('#', start);
      exp = end === -1 ? next.substring(start) : next.substring(start, end);
    }

    $scope.menuItems = getMenuItems($rootScope, undefined, angular.copy(sidebar));
  });
});

function getMenuItems($rootScope, namespace, sidebar) {
  return sidebar.filter(function (item) {
    if (!$rootScope.login.role) return false;
    if (item.admin) return $rootScope.login.role.admin;
    if (item.clearance) return $rootScope.login.role.approvalLevel >= item.clearance;
    if (typeof item.auth === 'function') return item.auth($rootScope, namespace);
    return true;
  }).map(function (item) {
    return parseMenuItem($rootScope, namespace, item);
  })
}

function parseMenuItem($rootScope, namespace, item) {
  if (typeof item.title === 'function') item.title = item.title($rootScope, namespace);
  if (typeof item.fixedHref === 'function') item.fixedHref = item.fixedHref($rootScope, namespace);
  if (typeof item.subMenu === 'function') item.subMenu = item.subMenu($rootScope, namespace);

  if (item.subMenu instanceof Array) {
    item.subMenu = item.subMenu.filter(function (subItem) {
      if (!$rootScope.login.role) return false;
      if (subItem.admin) return $rootScope.login.role.admin;
      if (subItem.clearance) return $rootScope.login.role.approvalLevel >= subItem.clearance;
      if (typeof subItem.auth === 'function') return subItem.auth($rootScope, namespace);
      return true;
    }).map(function (subItem) {
      return parseMenuItem($rootScope, namespace, subItem);
    });
  }
  return item;
}