app.constant("lookup", {
  nationality: [
    "Português",
    "Afegão",
    "Alemão",
    "Americano",
    "Angolano",
    "Antiguano",
    "Árabe, emiratense",
    "Argélia",
    "Argentino",
    "Armeno",
    "Australiano",
    "Austríaco",
    "Bahamense",
    "Bangladesh",
    "Barbadiano",
    "Bechuano",
    "Belga",
    "Belizenho",
    "Boliviano",
    "Brasileiro",
    "Britânico",
    "Camaronense",
    "Canadense",
    "Chileno",
    "Chinês",
    "Cingalês",
    "Colombiano",
    "Comorense",
    "Costarriquenho",
    "Croata",
    "Cubano",
    "Dinamarquês",
    "Dominicana",
    "Dominicano",
    "Egípcio",
    "Equatoriano",
    "Escocês",
    "Eslovaco",
    "Esloveno",
    "Espanhol",
    "Francês",
    "Galês",
    "Ganés",
    "Granadino",
    "Grego",
    "Guatemalteco",
    "Guianense",
    "Guianês",
    "Haitiano",
    "Holandês",
    "Hondurenho",
    "Húngaro",
    "Iemenita",
    "Indiano",
    "Indonésio",
    "Inglês",
    "Iraniano",
    "Iraquiano",
    "Irlandês",
    "Israelita",
    "Italiano",
    "Jamaicano",
    "Japonês",
    "Líbio",
    "Malaio",
    "Marfinense",
    "Marroquino",
    "Mexicano",
    "Moçambicano",
    "Neozelandês",
    "Nepalês",
    "Nicaraguense",
    "Nigeriano",
    "Norte-coreano",
    "Noruego",
    "Omanense",
    "Palestino",
    "Panamenho",
    "Paquistanês",
    "Paraguaio",
    "Peruano",
    "Polonês",
    "Portorriquenho",
    "Qatarense",
    "Queniano",
    "Romeno",
    "Ruandês",
    "Russo",
    "Salvadorenho",
    "Santa-lucense",
    "São-cristovense",
    "São-vicentino",
    "Saudita",
    "Sérvio",
    "Sírio",
    "Somali",
    "Sueco",
    "Suíço",
    "Sul-africano",
    "Sul-coreano",
    "Surinamês",
    "Tailandês",
    "Timorense, maubere",
    "Trindadense",
    "Turco",
    "Ucraniano",
    "Ugandense",
    "Uruguaio",
    "Venezuelano",
    "Vietnamita",
    "Zimbabuense"
  ],
  country: [
    "Portugal",
    "Antígua e Barbuda",
    "Afeganistão",
    "África do Sul",
    "Alemanha",
    "Angola",
    "Arábia Saudita",
    "Argélia",
    "Argentina",
    "Armênia",
    "Armeno",
    "Austrália",
    "Áustria",
    "Bahamas",
    "Barbados",
    "Bélgica",
    "Belize",
    "Bolívia",
    "Botsuana",
    "Brasil",
    "Camarões",
    "Canadá",
    "Chile",
    "China",
    "Colômbia",
    "Comores",
    "Coréia do Norte",
    "Coréia do Sul",
    "Costa do Marfim",
    "Costa Rica",
    "Croácia",
    "Cuba",
    "Dinamarca",
    "Dominica",
    "Egito",
    "El Salvador",
    "Emirados Árabes Unidos",
    "Equador",
    "Escócia",
    "Eslováquia",
    "Eslovênia",
    "Espanha",
    "Estados Unidos",
    "França",
    "Gana",
    "Granada",
    "Grécia",
    "Guatemala",
    "Guiana",
    "Guiana Francesa",
    "Haiti",
    "Honduras",
    "Hungria",
    "Iêmen",
    "Índia",
    "Indonésia",
    "Inglaterra",
    "Irã",
    "Iraque",
    "Irlanda",
    "Israel",
    "Itália",
    "Jamaica",
    "Japão",
    "Líbia",
    "Malásia",
    "Marrocos",
    "México",
    "Moçambique",
    "Nepal",
    "Nicarágua",
    "Nigéria",
    "Noruega",
    "Nova Zelândia",
    "Omã",
    "País de Gales",
    "Países Baixos",
    "Palestina",
    "Panamá",
    "Paquistão",
    "Paraguai",
    "Peru",
    "Polônia",
    "Porto Rico",
    "Qatar",
    "Quênia",
    "Reino Unido",
    "República Dominicana",
    "Romênia",
    "Ruanda",
    "Rússia",
    "Santa Lúcia",
    "São Cristóvão e Nevis",
    "São Vicente e Granadinas",
    "Sérvio",
    "Síria",
    "Somália",
    "Sri Lanka",
    "Suécia",
    "Suíça",
    "Suriname",
    "Tailândia",
    "Timor-Leste",
    "Trinidad e Tobago",
    "Turquia",
    "Ucrânia",
    "Uganda",
    "Uruguai",
    "Venezuela",
    "Vietnã",
    "Zimbabue"
  ]
});