app.directive('editAnviz', function () {
  return {
    restrict: 'A',
    scope: {
      item: '=',
      lookup: '=',
      form: '='
    },
    templateUrl: 'directives/edit/anviz/anviz.html',
    controller: function ($scope, $auth, $entity, lookup) {
      $scope.ready = false;
      $scope.lookup = $scope.lookup || {};
      $scope._form = $scope.form;
      $scope.isAdmin = $auth.isAdmin;

      for (var key in lookup) $scope.lookup[key] = lookup[key];
      $entity.lookup('arho').then(function (response) {
        $scope.lookup.arho = response.data;
      }).catch(function (response) {
        swal("Não foi possível obter dados", response.data, "error");
      }).finally(function () {
        $scope.ready = true;
      });
    }
  };
});