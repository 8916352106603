app.directive('selectAddress', function () {
  return {
    restrict: 'A',
    scope: {
      item: '=',
      lookup: '=',
      form: '='
    },
    replace: false,
    templateUrl: 'directives/address/address.html',
    controller: function ($scope, $address, zapi) {
      $scope.validateZipcode = $address.validateZipcode;

      $scope.getAddressPT = function (zipcode) {
        $address.getAddressPT(zipcode, function (err, data) {
          if (err) return swal("Atenção", "Não foi possível completar o arruamento", "warning");

          $scope.item.address = data.address;
          $scope.item.city = data.city;
          $scope.item.locality = data.locality;
          $scope.item.county = data.county;
          $scope.item.country = "Portugal";
        });
      };
    }
  };
});