app.controller('profileCtrl', function ($page, $scope, $http, mySocket, moment) {
  $page.setTitle('Visão Global');

  $scope.console = [];
  mySocket.on('console', function (data) {
    var length = $scope.console.unshift(data);
    if (length > 100) $scope.console.pop();
  });

  $scope.charts = [];
  $scope.items = [];
  $http.get('/api/statistics/all', { cache: true }).then(function (response) {
    var items = response.data;
    var activityChart = getFrameWithFixedSeries(response.data, ["Users", "Posts", "Uploads (total)", "Emails (total)"]);
    var systemChart = getFrameWithFixedSeries(response.data, ["Tasks (errored)", "Errors (distinct)"]);
    var costChart = getFrameWithFixedSeries(response.data, ["Storage (mb)"]);
    var attendanceChart = getFrameWithFixedSeries(response.data, ["Anviz->Manager", "Manager->ARHO", "Retificações"]);
    var anvizChart = getFrameWithFixedSeries(response.data, ["Desvio Horas"]);
    items.forEach(function (item) {
      var labelIndex = activityChart.labels.indexOf(moment(item.date).format('YYYY-MM-DD'));
      //activityChart
      //Users
      activityChart.data[0][labelIndex] = (activityChart.data[0][labelIndex] || 0) + item.users;
      //Posts
      activityChart.data[1][labelIndex] = (activityChart.data[1][labelIndex] || 0) + item.posts;
      //Uploads (total)
      activityChart.data[2][labelIndex] = (activityChart.data[2][labelIndex] || 0) + item.uploads.total;
      //Emails (total)
      activityChart.data[3][labelIndex] = (activityChart.data[3][labelIndex] || 0) + item.emailsSent.total;

      //systemChart
      //Tasks (errored)
      systemChart.data[0][labelIndex] = (systemChart.data[0][labelIndex] || 0) + item.tasks.errored.distinct;
      //Errors (distinct)
      systemChart.data[1][labelIndex] = (systemChart.data[1][labelIndex] || 0) + item.errors.distinct;

      //costChart
      //Storage (mb)
      costChart.data[0][labelIndex] = (costChart.data[0][labelIndex] || 0) + item.storageSize + item.rom;

      //attendanceChart
      //Anviz->Manager
      attendanceChart.data[0][labelIndex] = (attendanceChart.data[0][labelIndex] || 0) + item.anvizToManager;

      //Manager->ARHO
      attendanceChart.data[1][labelIndex] = (attendanceChart.data[1][labelIndex] || 0) + item.attendances.biometric;

      //Retificações
      attendanceChart.data[2][labelIndex] = (attendanceChart.data[2][labelIndex] || 0) + item.attendances.manual;

      //anvizChart
      //Desvio Horas
      anvizChart.data[0][labelIndex] = (anvizChart.data[0][labelIndex] || 0) + item.datetimeOffset;
    });

    $scope.charts.push(activityChart);
    $scope.charts.push(systemChart);
    $scope.charts.push(costChart);
    $scope.charts.push(attendanceChart);
    $scope.charts.push(anvizChart);
    $scope.items = items;
  }).catch(function (response) {
    console.error(response);
  });

  function getFrameWithFixedSeries(items, series) {
    var labels = [];
    var data = [];
    items.forEach(function (item) {
      var label = moment(item.date).format('YYYY-MM-DD');
      if (!labels.includes(label)) labels.push(label);
    });
    series.forEach(function () {
      var row = [];
      labels.forEach(function () {
        row.push();
      });
      data.push(row);
    });
    var options = {
      responsive: true,
      responsiveAnimationDuration: 1500,
      tooltip: { enabled: true },
      elements: {
        line: { tension: 0.4, stepped: false }
      },
      legend: { display: true }
    };
    return {
      labels: labels,
      series: series,
      data: data,
      options: options
    };
  }

  function getFrameWithDynamicSeries(items) {
    var labels = [];
    var series = [];
    var data = [];
    items.forEach(function (item) {
      var label = moment(item.date).format('YYYY-MM-DD');
      if (!labels.includes(label)) labels.push(label);

      var serie = item.arho;
      if (!series.includes(serie)) series.push(serie);
    });
    series.forEach(function () {
      var row = [];
      labels.forEach(function () {
        row.push();
      });
      data.push(row);
    });
    return {
      labels: labels,
      series: series,
      data: data,
      options: { responsive: false }
    };
  }
});