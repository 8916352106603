app.constant('projectTitle', 'panel');

app.constant('projectMaker', 'ARHO');

app.constant('anonymousPages', [
  'login',
  'activation',
  'notfound',
  ''
]);

app.constant('routeDepth', 1);

app.constant('customPages', [
  { urlParts: ['profile'] }
]);