app.constant('states', {
  'homepage': {
    title: 'Entrar',
    url: '/',
    controller: 'loginCtrl',
    templateUrl: 'custom/login/login.html'
  },
  'login': {
    title: 'Entrar',
    url: '/login',
    controller: 'loginCtrl',
    templateUrl: 'custom/login/login.html'
  },
  'activation': {
    title: 'Ativação de Conta',
    url: '/activation/:code',
    controller: 'activationCtrl',
    templateUrl: 'custom/activation/activation.html'
  },
  'profile': {
    title: "Visão Global",
    url: '/profile',
    controller: 'profileCtrl',
    templateUrl: 'custom/profile/profile.html'
  },
  'entitylist': {
    url: '/:namespace/:entity/list',
    controller: 'entityCtrl',
    templateUrl: 'entity/entity.list.html'
  },
  'entitynew': {
    url: '/:namespace/:entity/edit',
    controller: 'entityCtrl',
    templateUrl: 'entity/entity.edit.html'
  },
  'entityedit': {
    url: '/:namespace/:entity/edit/:id',
    controller: 'entityCtrl',
    templateUrl: 'entity/entity.edit.html'
  },
  'entityview': {
    url: '/:namespace/:entity/view/:id',
    controller: 'entityCtrl',
    templateUrl: 'entity/entity.view.html'
  }
});