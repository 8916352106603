app.controller('loginCtrl', function ($scope, $rootScope, $auth, $location, $timeout, $page, $uibModalStack, $interpolate, blockUI) {
  $page.setTitle("Login");
  $scope.item = {};

  $timeout(function () {
    if ($rootScope.login && $rootScope.login._id) return $location.path("profile");
  });

  $scope.login = function () {
    if (!$scope.item.username) return;
    if (!$scope.item.password) return;

    blockUI.start($interpolate("{{'api.services.auth.loginLoad' | translate}}")($scope));
    $auth.login($scope.item.username, $scope.item.password).then(function (response) {
      $location.path('/profile');
      $uibModalStack.dismissAll();
    }).catch(function (response) {
      swal($interpolate("{{'api.services.auth.loginErrorTitle' | translate}}")($scope), $interpolate("{{'api.services.auth.loginErrorContent' | translate}}")($scope), "error");
      reset();
    }).finally(function () {
      blockUI.stop();
    });
  };

  function reset() {
    $scope.item.username = "";
    $scope.item.password = "";
  }
});